import React, { useEffect, useState } from "react";
import _ from "lodash";

export default function ViewAllBtn({ active, handleViewAll }) {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(active)) {
      Object.values(active).every((course) => course[0]) ? setActive(true) : setActive(false);
    }
  }, [active]);

  return isActive ? (
    <button
      className="px-6 py-2 bg-aggie-blue font-proxima text-[12px] text-white rounded-full"
      onClick={() => {
        setActive(false);
        handleViewAll(false);
      }}
    >
      View All
    </button>
  ) : (
    <button
      className="px-6 py-2 font-proxima text-[12px] text-slate-400 rounded-full border-2 border-slate-400"
      onClick={() => {
        setActive(true);
        handleViewAll(true);
      }}
    >
      View All
    </button>
  );
}
