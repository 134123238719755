import React, { useState } from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/NavBar";
import RouterPage from "./router-page";

const client = new ApolloClient({
  uri: `${process.env.react_app_api_url}/graphql`
});

function App() {
  //const [loggedIn, setLoggedIn] = useState();
  const clientID = sessionStorage.getItem("userToken");
  //console.log("current ID is ", clientID); // NEED to encrypt sessionstorage in future

  return (
    <ApolloProvider client={client}>
      <Router>
        <Navbar currentID={clientID} />
        <RouterPage />
      </Router>
    </ApolloProvider>
  );
}

export default App;
