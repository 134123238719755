import { React, useState, useEffect, useRef } from "react";
import WindowedSelect from "react-windowed-select";
import axios from "axios";

import ClassTabGrid from "./ClassTabGrid";
import ScheduleDisplay from "./ScheduleDisplay";

import { gunzip } from 'react-zlib-js';
import {Buffer} from 'buffer';

export default function UserPlanner() {
  const [courseName, setCourseName] = useState("");
  const [isLoading, setLoading] = useState(true);
  // courseData is used to render the ClassTabGrid component
  const [courseData, setCourseData] = useState([]);
  const [colorMap, setColorMap] = useState([]);
  // scheduleData is used to render the Scheduler component
  const [scheduleData, setScheduleData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  // enrollment info courses
  const [enrollmentCourses, setEnrollmentCourses] = useState([]);
  const [courseDropDown, setCourseDropDown] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  // Todo : get better colors from designers
  const courseColors = ["#E16E6E", "#95C5FB", "#FFC519", "magenta", "fuchsia"];
  const clientID = sessionStorage.getItem("userToken");
  // For loading search bar
  // useEffect to load enrollment info
  useEffect(() => {

    if(clientID == null) return
    
    async function getEnrollmentData() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/enrollment_info`, {})
        .then(async (data) => {
          // console.log("Initial res: ", data);
          let decompressed_data = Buffer.from(data.data.data, "base64");
          decompressed_data = await gunzip(decompressed_data, (err, decompressed_data) => {
            // console.log("In gunzip: ", decompressed_data);
            data = JSON.parse(decompressed_data.toString());
            let filteredData = data.filter(obj => {
                return obj["quarter"] === "FQ2022" 
                  // || obj["quarter"] === "SS12022"
            })
            console.log(filteredData);
            setEnrollmentCourses(filteredData); 
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    getEnrollmentData();
  }, []);

  function updateCourseList() {
    var courseList = [];
    console.log("updating with", enrollmentCourses);
    for (var i = 0; i < enrollmentCourses.length; i++) {
      courseList.push({
        value:
          enrollmentCourses[i].course_id +
          "-" +
          enrollmentCourses[i].crn +
          "-" +
          enrollmentCourses[i].instructor +
          "-" +
          enrollmentCourses[i].quarter,
        label: (
          <h5 style={{ marginTop: "5px" }}>
            {enrollmentCourses[i].course_id} - {enrollmentCourses[i].name} -{" "}
            {enrollmentCourses[i].instructor} - {enrollmentCourses[i].quarter}
          </h5>
        )
      });
    }
    setCourseDropDown(courseList);
  }
  useEffect(updateCourseList, [enrollmentCourses]);

  // useEffect isnt supposed to have async functions
  // - this just combines all async functions
  // and runs them
  // With google id, get user data in our DB
  async function getUserProfile(req_client_id) {
    //console.log("getting client profile", req_client_id);
    // Make DB call to planner-clients to get the saved courses
    // - these courses are objectIDs from enrollment_info
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/client_data`, {
      params: { client_id: req_client_id },
    });
    console.log(res)
    return res.data.data;
  }
  // Create profile if they are new
  async function createUserProfile(req_client_id) {
    //console.log("getting client profile", req_client_id);
    // Make DB call to planner-clients to get the saved courses
    // - these courses are objectIDs from enrollment_info
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}/client_data/create_profile`,
      {
        params: { client_id: req_client_id },
      }
    );
    return res.data.data;
  }

  // With objectIDs from returned client data, get the class times
  async function getUserCourses(req_obj_id) {
    //console.log("getting enrollment data for", req_obj_id);
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/enrollment_info/getMultipleIDs`,
      {
        params: req_obj_id,
      }
    );
    return res.data;
  }

  // update the schedule when we get courseData
  const updateSchedule = () => {
    const getExcludedDays = (days) => {
      let excludedDays = [1, 7]; //Sat, Sun
      if (days.includes("M") === false) {
        excludedDays.push(2);
      }
      if (days.includes("T") === false) {
        excludedDays.push(3);
      }
      if (days.includes("W") === false) {
        excludedDays.push(4);
      }
      if (days.includes("R") === false) {
        excludedDays.push(5);
      }
      if (days.includes("F") === false) {
        excludedDays.push(6);
      }
      return excludedDays;
    };

    const getTimes = (time) => {
      const timeArr = time.split(" ");
      const [startingHour, startingMin] = timeArr[0].split(":");
      const [endingHour, endingMin] = timeArr[2].split(":");
      return [
        parseInt(startingHour),
        parseInt(startingMin),
        parseInt(endingHour),
        parseInt(endingMin)
      ];
    };

    let appointmentList = [];
    for (const c in courseData) {
      const course = courseData[c];
      const timingData = course.timings[0];
      const daysSkipped = getExcludedDays(timingData.days);
      const [startH, startM, endH, endM] = getTimes(timingData.time);

      for (let i = 2; i < 7; i++) {
        if (daysSkipped.includes(i) === false) {
          const new_appt = {
            title: course.course_id,
            startDate: new Date(2022, 4, i, startH - 7, startM)
              .toISOString()
              .slice(0, -5),
            endDate: new Date(2022, 4, i, endH - 7, endM)
              .toISOString()
              .slice(0, -5),
          };
          console.log(new_appt);
          appointmentList.push(new_appt);
        }
      }
    }

    let finalsList = [];
    for (const c in courseData) {
      const course = courseData[c];
      const finalStr = course.final_exam;
      const fArr = finalStr.split(" ");
      //console.log(fArr);
      const day = Number(fArr[2]);
      //console.log("day\n", day);
      //const months = ["January","February","March","April","May","June","July","August","September","October","November","December",];
      //console.log(fArr[1]);
      const month = fArr[1];
      //console.log("month\n", month);
      const isAM = fArr[5] === "am" || fArr[5] === "AM" ? true : false;
      //console.log((fArr[4]))
      const hourMin = fArr[4].toString().split(":");
      //console.log("time", hourMin);
      const min = Number(hourMin[1]);
      const hour = isAM ? Number(hourMin[0]) : Number(hourMin[0]) + 12;
      const thisYr = "2022";
      const dateStart = month + " " + day + ", " + thisYr + " " + (hour - 7) + ":" + min + ":00";
      const dateEnd = month + " " + day + ", " + thisYr + " " + (hour + 2 - 7) + ":" + min + ":00";
      console.log(dateStart);
      console.log(dateEnd);
      console.log(hour, min);
      console.log("inserting a final date");
      const newFinal = {
        title: course.course_id,
        startDate: new Date(dateStart).toISOString().slice(0, -5),
        endDate: new Date(dateEnd).toISOString().slice(0, -5)
      };
      //console.log(newFinal);
      finalsList.push(newFinal);
    }

    setScheduleData(appointmentList);
    setFinalData(finalsList);
  };

  // When enrollment data returns, update the ClassTabGrid
  const updateCourseData = (classes) => {
    // refactor the data in order to add to state
    let newCourses = [];
    let newCMap = [];
    for (const key in classes) {
      const cur_course = classes[key];
      console.log("in", cur_course);
      const new_class = {
        _id: cur_course._id,
        course_id: cur_course.course_id,
        crn: cur_course.crn,
        fullName: cur_course.name,
        units: cur_course.units,
        instructor: cur_course.instructor,
        quarter: cur_course.quarter,
        year: cur_course.quarter,
        desc: cur_course.description,
        timings: cur_course.timings,
        final_exam: cur_course.final_exam
      };
      newCourses.push(new_class);
      newCMap.push(cur_course.course_id);
    }

    setCourseData(newCourses);
    setColorMap(newCMap);
  };

  useEffect(() => {
    
    const getAndSetUserData = async () => {
      try {
        let userData = await getUserProfile(clientID); //try get user
        if (userData) {
          console.log("Profile exists\n", userData);
          const userClasses = await getUserCourses(userData.course_object_ids); //get user stuff
          updateCourseData(userClasses);
        } else {
          console.log("Creating Profile\n", userData);
          await createUserProfile(clientID); //make user
          userData = await getUserProfile(clientID); //get user
          const userClasses = await getUserCourses(userData.course_object_ids); //get user stuff
          updateCourseData(userClasses);
        }

        setLoading(false); // Let user see loaded data
      } catch (err) {
        console.log("err", err);
      }
    };

    getAndSetUserData();
  }, []);

  useEffect(updateSchedule, [courseData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    getNewCourse();
  };

  const handleDropDownChange = (e) => {
    console.log("Event", e);
    // get the selected course and filter course detail info for desired course ID
    const splitData = e.value.split("-");
    console.log(splitData);
    const newCourse = {
      course_id: splitData[0],
      crn: splitData[1],
      instructor: splitData[2],
      quarter: splitData[3]
    };
    setSelectedCourse(newCourse);
  };

  async function updateUser(courseid) {
    //console.log("getting client profile", req_client_id);
    // Make DB call to planner-clients to get the saved courses
    // - these courses are objectIDs from enrollment_info
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}/client_data/update_courses`,
      {
        params: { client_id: clientID, id: courseid},
      }
    );
    return res.data.data;
  }

  const getNewCourse = async () => {
    console.log("inserting new course");
    try {
      console.log("finding", selectedCourse);
      const foundCourse = enrollmentCourses.filter((obj) => {
        return (
          obj["course_id"] == selectedCourse["course_id"] &&
          obj["crn"] == selectedCourse["crn"] &&
          obj["instructor"] == selectedCourse["instructor"] &&
          obj["quarter"] == selectedCourse["quarter"]
        );
      });
      if (foundCourse !== undefined || foundCourse.length === 0) {
        console.log(foundCourse[0]["_id"]);
        await updateUser(foundCourse[0]["_id"]);
        const userData = await getUserProfile(clientID); //try get user
        const userClasses = await getUserCourses(userData.course_object_ids); //get user stuff
        updateCourseData(userClasses);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  async function deleteUserCourse(courseid) {
    //console.log("getting client profile", req_client_id);
    // Make DB call to planner-clients to get the saved courses
    // - these courses are objectIDs from enrollment_info
    let res = await axios.post(`${process.env.REACT_APP_API_URL}/client_data/delete_course`, {
      params: { client_id: clientID, id: courseid }
    });
    return res.data.data;
  }

  async function deleteUserCourse(courseid) {
    //console.log("getting client profile", req_client_id);
    // Make DB call to planner-clients to get the saved courses
    // - these courses are objectIDs from enrollment_info
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}/client_data/delete_course`,
      {
        params: { client_id: clientID, id: courseid},
      }
    );
    return res.data.data;
  }

  const removeCourse = async (course) => {
    console.log("deleting course", course)
    await deleteUserCourse(course["_id"]);
    const userData = await getUserProfile(clientID); //try get user
    const userClasses = await getUserCourses(userData.course_object_ids); //get user stuff
    updateCourseData(userClasses);
    // try {
    //   console.log("finding", course);
    //   const foundCourse = enrollmentCourses.filter(obj => {
    //     return (obj["course_id"] == course["course_id"] && obj["crn"] == course["crn"] && obj["instructor"] == course["instructor"] && obj["quarter"] == course["quarter"])
    //   })
    //   if(foundCourse !== undefined || foundCourse.length === 0) {
    //     console.log("found this", foundCourse[0]);
    //     await deleteUserCourse(foundCourse[0]["_id"]);
    //     const userData = await getUserProfile(clientID); //try get user
    //     const userClasses = await getUserCourses(userData.course_object_ids); //get user stuff
    //     updateCourseData(userClasses);
    //   }
    // } catch(err) {
    //   console.log("err", err);
    // }
  }

  var classPlaceholder = (
    <h5 style={{ marginTop: "5px", color: "#BEBEBE" }}> Search for a class </h5>
  );

  return (
    <form className="h-full" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-6">
        <h1 className="font-proxima-semi-bold text-aggie-blue text-[26px] m-0">Your Planner</h1>
        <div className="flex">
          <WindowedSelect
            className="w-full"
            styles={{
              height: 20
            }}
            onChange={handleDropDownChange}
            options={courseDropDown}
            filterOption={({ value }, input) => {
              const subj = value.substring(0, 3),
                course = value.substring(3);
              if (input.length <= 3) {
                return subj.includes(input.toUpperCase());
              } else {
                return (
                  subj === input.substring(0, 3).toUpperCase() &&
                  course.includes(input.substring(3))
                );
              }
            }}
            placeholder={classPlaceholder}
          />
          <button
            onClick={handleSubmit}
            className="p-3 rounded-lg ml-6 w-48 text-center text-lg font-bold bg-yellow-300"
          >
            Add Class
          </button>
        </div>
        {isLoading ? (
          <p>loading</p>
        ) : courseData.length === 0 ? (
          <p>No Courses? Add some!</p>
        ) : (
          <div>
            <ClassTabGrid
              courses={courseData}
              colors={courseColors}
              sendDelete={removeCourse}
            ></ClassTabGrid>
            <ScheduleDisplay
              schedData={scheduleData}
              finData={finalData}
              colors={courseColors}
              colorMap={colorMap}
            ></ScheduleDisplay>
          </div>
        )}
      </div>
    </form>
  );
}
