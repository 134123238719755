import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import Grades from "./pages/Grades";
import List from "./pages/List";
import Edit from "./pages/Edit";
import Enrollment from "./pages/Enrollment";
import Create from "./pages/Create";
import About from "./pages/About";
import Dashboard from "./pages/Dashboard"
import GraphClientTest from "./graphClientTest";

export default function RouterPage(props) {
  return (
    <Switch>
      <Route exact path="/grades/:class/:quarter/:instructor1" component={(props) => <Grades {...props} />} />
      <Route path="/" exact component={Home} />
      <Route path="/catalog" component={Catalog} />
      <Route path="/grades" component={Grades} />
      <Route path="/list" component={List} />
      <Route path="/enrollment" component={Enrollment} />
      <Route path="/edit" component={Edit} />
      <Route path="/create" component={Create} />
      <Route path="/about" component={About} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/test" component={GraphClientTest} />
    </Switch>
  );
}
// Example of a route based on a specific course: (uncomment it)
{
  /* <Route
    exact
    path="/course/:courseId"
    component={(props) => <SomeComponent {...props} />}
/> */
}
