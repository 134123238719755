import React, { useState } from "react";
import "../styles/feature-btn.css";

export default function BtnGrid({ buttons, setFunction }) {
  const [clickedId, setClickedId] = useState(0);

  const handleClick = (id) => {
    setClickedId(id);
    setFunction(id);
  };

  return (
    <div id="btnGrid">
      {buttons.map((btn, i) => (
        <button
          key={i}
          name={btn.name}
          onClick={() => handleClick(i)}
          className={i === clickedId ? "featureBtn active" : "featureBtn"}
        >
          <h1 id="btnName">{btn.name}</h1>
          <p id="btnDesc">{btn.desc}</p>
        </button>
      ))}
    </div>
  );
}
