import { React, useState, useEffect } from "react";
import axios from "axios";
import ActiveAddBtn from "../images/active-add-btn.svg";
import InactiveAddBtn from "../images/inactive-add-btn.svg";
import ExpandArrow from "../images/expand-arrow.svg";

export default function UserProfile() {
  const [canEdit, toggleEdit] = useState(false);
  const [profile, setProfile] = useState({});
  const [majorCount, setMajorCount] = useState(1);
  const [minorCount, setMinorCount] = useState(1);
  const clientID = sessionStorage.getItem("userToken");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client_data`, {
        params: { client_id: clientID }
      })
      .then((res) => {
        if(res.data.data == null) return
        setProfile(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProfile((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleEdit(false);
    axios.put(`${process.env.REACT_APP_API_URL}/client_data/update_profile`, {
      client_id: clientID,
      name: profile.name,
      email: profile.email,
      firstMajor: profile.firstMajor,
      secondMajor: profile.secondMajor,
      thirdMajor: profile.thirdMajor,
      firstMinor: profile.firstMinor,
      secondMinor: profile.secondMinor,
      thirdMinor: profile.thirdMinor,
      passOne: profile.passOne,
      passTwo: profile.passTwo
    });
  };

  return (
    <form className="h-full flex flex-col gap-20" onSubmit={handleSubmit}>
      <div className="flex flex-col w-full h-[300px] items-center justify-center bg-cover rounded-[20px] bg-[url('../images/welcome-banner-bg.svg')]">
        <h1 className="font-proxima-bold text-5xl text-aggie-blue">Welcome Aggies,</h1>
        <p className="font-proxima text-3xl text-aggie-blue">
          Please enter your student information below before
        </p>
        <div className="flex">
          <h2 className="font-proxima text-4xl text-aggie-blue">Aggie</h2>
          <h2 className="font-proxima-bold italic text-4xl text-aggie-gold">Exploring</h2>
        </div>
        <a href="#info" className="relative top-12">
          <img src={ExpandArrow} />
        </a>
      </div>
      <div id="info" className="h-full flex flex-col gap-24">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row items-center gap-10 pb-4">
            <h1 className="font-proxima-semi-bold text-aggie-blue text-[26px] m-0">
              Personal Information
            </h1>
            <p
              className="font-proxima text-aggie-blue text-2xl m-0 hover:underline cursor-pointer"
              onClick={() => toggleEdit(!canEdit)}
            >
              Edit
            </p>
          </div>
          <div>
            <label className="font-proxima text-aggie-blue text-3xl">First and Last Name</label>
            <div className="h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">{profile.name}</p>
            </div>
            <input
              type="text"
              name="name"
              className="w-full h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
              hidden={!canEdit}
              value={profile.name || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="font-proxima text-aggie-blue text-3xl">UC Davis Student Email</label>
            <div className="h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">{profile.email}</p>
            </div>
            <input
              type="email"
              name="email"
              className="w-full h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
              hidden={!canEdit}
              value={profile.email || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label className="font-proxima text-aggie-blue text-3xl">Major(s)</label>
            <div className="flex h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">
                {[profile.firstMajor, profile.secondMajor, profile.thirdMajor]
                  .filter((major) => major && major.trim().length)
                  .join(", ")}
              </p>
            </div>
            <div className="flex gap-5 items-center">
              <input
                type="text"
                name="firstMajor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit}
                value={profile.firstMajor || ""}
                onChange={handleChange}
              />
              <input
                type="text"
                name="secondMajor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit || majorCount < 2}
                value={profile.secondMajor || ""}
                onChange={handleChange}
              />
              <input
                type="text"
                name="thirdMajor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit || majorCount < 3}
                value={profile.thirdMajor || ""}
                onChange={handleChange}
              />
              {(majorCount === 1 && profile.firstMajor) ||
              (majorCount === 2 && profile.secondMajor) ? (
                <img
                  src={ActiveAddBtn}
                  onClick={() => {
                    setMajorCount((prevState) => prevState + 1);
                  }}
                  className="cursor-pointer"
                  hidden={!canEdit}
                />
              ) : (
                <img src={InactiveAddBtn} hidden={!canEdit} />
              )}
            </div>
          </div>
          <div>
            <label className="font-proxima text-aggie-blue text-3xl">Minor(s)</label>
            <div className="flex h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">
                {[profile.firstMinor, profile.secondMinor, profile.thirdMinor]
                  .filter((minor) => minor && minor.trim().length)
                  .join(", ")}
              </p>
            </div>
            <div className="flex gap-5 items-center">
              <input
                type="text"
                name="firstMinor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit}
                value={profile.firstMinor || ""}
                onChange={handleChange}
              />
              <input
                type="text"
                name="secondMinor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit || minorCount < 2}
                value={profile.secondMinor || ""}
                onChange={handleChange}
              />
              <input
                type="text"
                name="thirdMinor"
                className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
                hidden={!canEdit || minorCount < 3}
                value={profile.thirdMinor || ""}
                onChange={handleChange}
              />
              {(minorCount === 1 && profile.firstMinor) ||
              (minorCount === 2 && profile.secondMinor) ? (
                <img
                  src={ActiveAddBtn}
                  onClick={() => {
                    setMinorCount((prevState) => prevState + 1);
                  }}
                  className="cursor-pointer"
                  hidden={!canEdit}
                />
              ) : (
                <img src={InactiveAddBtn} hidden={!canEdit} />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <div>
            <h1 className="font-proxima-semi-bold text-aggie-blue text-[26px] mb-3">
              Quarter Registration Information
            </h1>
            <h2 className="font-proxima text-aggie-blue text-3xl">
              Quarter Registration Pass Times can be found on Schedule Builder via the student
              portal for myucdavis.com
            </h2>
          </div>
          <div className="flex flex-col">
            <label className="font-proxima text-aggie-blue text-3xl">Pass Time One</label>
            <div className="h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">{profile.passOne}</p>
            </div>
            <input
              type="date"
              name="passOne"
              className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
              hidden={!canEdit}
              value={profile.passOne || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="font-proxima text-aggie-blue text-3xl">Pass Time Two</label>
            <div className="h-12" hidden={canEdit}>
              <p className="font-proxima text-[#5A5A5A] text-xl my-2">{profile.passTwo}</p>
            </div>
            <input
              type={"date"}
              name="passTwo"
              className="w-1/6 h-12 rounded-full border-2 border-slate-200 px-4 text-xl font-proxima"
              hidden={!canEdit}
              value={profile.passTwo || ""}
              onChange={handleChange}
              required
            />
          </div>
          {profile.name && profile.email && profile.passOne && profile.passTwo ? (
            <input
              type="submit"
              value="Save"
              hidden={!canEdit}
              className="w-52 h-14 self-end bg-[#FFC519] rounded-full font-proxima-bold text-2xl text-aggie-blue"
            />
          ) : (
            <input
              type="submit"
              value="Save"
              className="w-52 h-14 self-end bg-slate-200 rounded-full font-proxima-bold text-2xl text-slate-400"
              hidden={!canEdit}
              disabled
            />
          )}
        </div>
      </div>
    </form>
  );
}
