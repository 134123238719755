import { React, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import { ViewState } from "@devexpress/dx-react-scheduler";

import SwitchButton from './SwitchButton';

import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";

export default function ScheduleDisplay({ schedData, finData, colors, colorMap }) {
  const [scheduleTab, setScheduleTab] = useState("FinalExam");
  const [scheduleData, setScheduleData] = useState([]);
  const [finalData, setFinalData] = useState([]);

  const currentDate = "2022-05-01";
  const finalsDate = "2022-12-04"
  // console.log("Display has", schedData);

  useEffect(() => {
    setScheduleData(schedData);
  }, [schedData]);

  useEffect(() => {
    setFinalData(finData);
  }, [finData]);

  // To style days
  const PREFIX = "Demo";
  const classes = {
    appointment: `${PREFIX}-appointment`,
    apptContent: `${PREFIX}-apptContent`,
  };

  const StyledAppointmentsAppointment = styled(Appointments.Appointment)(
    () => ({
      [`&.${classes.appointment}`]: {
        borderRadius: "5px",
        "&:hover": {
          opacity: 0.6,
        },
      },
      [`&.apptRed`]: {
        backgroundColor: colors[0],
        "&:hover": {
          backgroundColor: colors[0],
        },
      },
      [`&.apptBlue`]: {
        backgroundColor: colors[1],
        "&:hover": {
          backgroundColor: colors[1],
        },
      },
      [`&.apptYellow`]: {
        backgroundColor: colors[2],
        "&:hover": {
          backgroundColor: colors[2],
        },
      },
      [`&.apptMagenta`]: {
        backgroundColor: colors[3],
        "&:hover": {
          backgroundColor: colors[3],
        },
      },
      [`&.apptFuchsia`]: {
        backgroundColor: colors[4],
        "&:hover": {
          backgroundColor: colors[4],
        },
      },
    })
  );

  const Appointment = ({ ...restProps }) => {
    const courseName = restProps.data.title;
    const index = colorMap.indexOf(courseName);
    let classRef = classes.appointment;
    switch (index) {
      case 0:
        classRef = "apptRed";
        break;
      case 1:
        classRef = "apptBlue";
        break;
      case 2:
        classRef = "apptYellow";
        break;
      case 3:
        classRef = "apptMagenta";
        break;
      case 4:
        classRef = "apptFuchsia";
        break;
      default:
        break;
    }

    return (
      <StyledAppointmentsAppointment {...restProps} className={classes.appointment + " " + classRef} />
    );
  };

  const StyledAppointmentsAppointmentContent = styled(
    Appointments.AppointmentContent
  )(() => ({
    [`&.${classes.apptContent}`]: {
      "&>div>div": {
        whiteSpace: "normal !important",
        fontSize: "1.5em",
        lineHeight: 1.2,
      },
    },
  }));

  const AppointmentContent = ({ ...restProps }) => (
    <StyledAppointmentsAppointmentContent
      {...restProps}
      className={classes.apptContent}
    />
  );

  if (scheduleData.length === 0) {
    return <p>Loading</p>;
  }
  if (scheduleTab === "FinalExam") {
    return (
      <>
        <Paper>
          <Scheduler data={finalData} height={400}>
            <ViewState currentDate={finalsDate} />
            <WeekView
              startDayHour={6}
              endDayHour={22}
              cellDuration={60}
              excludedDays={[0, 6]}
            />
            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />
            {/* <AppointmentTooltip showCloseButton /> */}
          </Scheduler>
        </Paper>
        <div
          onClick={() => setScheduleTab("CourseSchedule")}
          className={"flex justify-center"}
        >
          <SwitchButton option1={"Final Exam"} option2={"Course Schedule"} chosen={0}/>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Paper>
          <Scheduler data={scheduleData} height={400}>
            <ViewState currentDate={currentDate} />
            <WeekView
              startDayHour={6}
              endDayHour={22}
              cellDuration={60}
              excludedDays={[0, 6]}
            />
            <Appointments
              appointmentComponent={Appointment}
              appointmentContentComponent={AppointmentContent}
            />
            {/* <AppointmentTooltip showCloseButton /> */}
          </Scheduler>
        </Paper>
        <div
          onClick={() => setScheduleTab("FinalExam")}
          className={"flex justify-center"}
        >
          <SwitchButton option1={"Final Exam"} option2={"Course Schedule"} chosen={1}/>
        </div>
      </>
    );
  }
}
