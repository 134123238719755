import React from "react";

export default function StatsTab({ course, active, setActive }) {
  return active[0] ? (
    <button
      className="px-6 py-2 bg-aggie-blue font-proxima text-[12px] text-white rounded-full"
      onClick={() => {
        setActive((prevState) => ({
          ...prevState,
          [course._id]: [false, prevState[course._id][1]]
        }));
      }}
    >
      {`${course.subj} ${course.code}`}
    </button>
  ) : (
    <button
      className="px-6 py-2 font-proxima text-[12px] text-slate-400 rounded-full border-2 border-slate-400"
      onClick={() => {
        setActive((prevState) => ({
          ...prevState,
          [course._id]: [true, prevState[course._id][1]]
        }));
      }}
    >
      {`${course.subj} ${course.code}`}
    </button>
  );
}
