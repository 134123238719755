import React, { Component } from "react";
import axios from "axios";
import WindowedSelect from "react-windowed-select";
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import _ from "lodash";
import bg from "../images/home-bg.png";

import { gunzip } from 'react-zlib-js';
import {Buffer} from 'buffer';

// Default class to export
// This component contains two main sections
//    1. selection of course on top of page
//    2. Graph of grades and legend on bottom of page
export default class GradePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.courses = []; //this stores the course jsons from the database
    this.state.formatted_data_for_graph = [];
    this.state.selected_courses_aggregate = [];
    this.state.courseCards = [];
    this.state.chartLegend = [];

    //store the drop down menu opstions
    this.state.course_drop_down_menu_options = [];
    this.state.quarter_drop_down_menu_options = [];
    this.state.instructor_drop_down_menu_options = [];

    //selected values from the drop down menus
    this.state.selected_course_id = null;
    this.state.selected_course_id_quarter = null;
    this.state.selected_course_id_quarter_instructor = null;
    this.state.enableAddCourse = false;

    this.handleCourseChangeRef = this.handleCourseChange.bind(this);
    this.handleQuarterChangeRef = this.handleQuarterChange.bind(this);
    this.handleInstructureChangeRef = this.handleInstructureChange.bind(this);

    this.addCourseRef = this.addCourse.bind(this);
    this.handleCourseDeleteRef = this.handleCourseDelete.bind(this);

    // used for placeholder values
    this.courseListRef = React.createRef();
    this.quarterListRef = React.createRef();
    this.instructorListRef = React.createRef();
    this.MAX_X_AXIS_NUM_ELEMENTS = 4;
    this.COURSE_DETAIL_COLOR = ["#FF8189", "#00B2E3", "#FFDA6D", "#74EA37"];
    this.state.currentCourseColorIndex = 0;

    this.single_course_aggregates = [];
  }

  componentDidMount() {
    //get all the course id's from the database query
    this.getEnrollmentData();
  }

  async getEnrollmentData() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/enrollment_info`, {})
      .then(async (data) => {
        // console.log("Initial res: ", data);
        let decompressed_data = Buffer.from(data.data.data, "base64");
        decompressed_data = await gunzip(decompressed_data, (err, decompressed_data) => {
            // console.log("In gunzip: ", decompressed_data);
            data = JSON.parse(decompressed_data.toString());
            this.setState({ courses: data });
            this.updateCourseList();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getCourseSeats(id, courseCount) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/enrollment/` + id, {})
      .then((data) => {
        console.log(data.data.data);
        this.single_course_aggregates.push(data.data.data);
        if(this.single_course_aggregates.length == courseCount) {
          this.addCourseComplete();
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }

  handleCourseDelete(event) {
    const courseIDRef = event.target.getAttribute("course-id-ref"),
      quarterRef = event.target.getAttribute("quarter-ref");
    let deleteIndex = 0;
    this.setState({
      selected_courses_aggregate: this.state.selected_courses_aggregate.filter((course) => {
        if (course.course_id == courseIDRef && course.quarter == quarterRef) {
          deleteIndex = this.state.selected_courses_aggregate.indexOf(course);
          console.log("deleteIndex: ", deleteIndex);
          return false;
        } else {
          return true;
        }
      }),
      courseCards: this.state.courseCards.filter(
        (courseCard) => courseCard !== this.state.courseCards[deleteIndex]
      ),
      chartLegend: this.state.chartLegend.filter(
        (line) => line !== this.state.chartLegend[deleteIndex]
      )
    });
    if (
      this.state.selected_courses_aggregate.length === 0 ||
      this.state.currentCourseColorIndex === this.COURSE_DETAIL_COLOR.length - 1
    ) {
      this.setState({ currentCourseColorIndex: 0 });
    }
  }

  updateCourseList() {
    var courseList = [];
    for (var i = 0; i < this.state.courses.length; i++) {
      courseList.push({
        value: this.state.courses[i].course_id + " " + this.state.courses[i].name,
        label: (
          <h5 style={{ marginTop: "5px" }}>
            {this.state.courses[i].course_id} - {this.state.courses[i].name}
          </h5>
        )
      });
    }
    this.setState({ course_drop_down_menu_options: courseList });
    console.log(this.state.course_drop_down_menu_options);
  }

  formatData() {
    const addedCourseIndex = this.state.selected_courses_aggregate.length - 1;
    let data = [];

    if (this.state.selected_courses_aggregate.length == 0) {
      console.log('"this.state.selected_courses_aggregate" is empty');
      return;
    }

    for (let i = 0; i < this.state.selected_courses_aggregate[addedCourseIndex].seats.length; i++) {
      let each_point = {};
      each_point["name"] = i;
      // let total_seats = this.state.selected_courses_aggregate
      let cur_class_max = this.state.selected_courses_aggregate[addedCourseIndex].seats[0];
      let cur_point_seats = this.state.selected_courses_aggregate[addedCourseIndex].seats[i];
      let cur_point_percentage = (cur_point_seats / cur_class_max) * 100;
      each_point[this.state.selected_courses_aggregate[addedCourseIndex].graph_id] =
        cur_point_percentage.toFixed(3);
      data.push(each_point);
    }

    const updateGraphData = () => {
      let graphDataResult = [];
      if (this.state.formatted_data_for_graph.length === 0) {
        graphDataResult = data;
      } else {
        let addedCourseGraphID = this.state.selected_courses_aggregate[addedCourseIndex].graph_id;
        let modifiedGraphData = [...this.state.formatted_data_for_graph, ...data];
        modifiedGraphData.sort((itemOne, itemTwo) => itemOne.name - itemTwo.name);
        for (let i = 0; i < modifiedGraphData.length - 1; i += 2) {
          if (modifiedGraphData[i].name === modifiedGraphData[i + 1].name) {
            graphDataResult.push({
              ...modifiedGraphData[i],
              [addedCourseGraphID]: modifiedGraphData[i + 1][addedCourseGraphID]
            });
          }
        }
      }
      return graphDataResult;
    };

    // Change abbreviated quarter names into full names to display
    const changeQuarterName = (quarter) => {
      const abbreviation = quarter;
      let fullName = "";
      if (abbreviation.substring(0, 2) !== "SS") {
        switch (abbreviation.substring(0, 2)) {
          case "FQ":
            fullName = "Fall Quarter " + abbreviation.substring(2);
            break;
          case "WQ":
            fullName = "Winter Quarter " + abbreviation.substring(2);
            break;
          case "SQ":
            fullName = "Spring Quarter " + abbreviation.substring(2);
            break;
        }
      } else {
        switch (abbreviation.substring(2, 3)) {
          case "1":
            fullName = "Summer Session 1 " + abbreviation.substring(3);
            break;
          case "2":
            fullName = "Summer Session 2 " + abbreviation.substring(3);
            break;
        }
      }
      return fullName;
    };

    this.setState(
      {
        formatted_data_for_graph: updateGraphData(),
        chartLegend: [
          ...this.state.chartLegend,
          <Line
            name={this.state.selected_courses_aggregate[addedCourseIndex].graph_id}
            dataKey={this.state.selected_courses_aggregate[addedCourseIndex].graph_id}
            stroke={this.COURSE_DETAIL_COLOR[this.state.currentCourseColorIndex]}
            strokeWidth={5}
            type={"basis"}
            r={0}
          />
        ],
        courseCards: [
          ...this.state.courseCards,
          <div
            style={{
              border: "2px solid #BEBEBE",
              borderRadius: "10px",
              padding: "10px",
              whiteSpace: "nowrap"
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                className="dot"
                style={{
                  borderRadius: "50%",
                  backgroundColor: this.COURSE_DETAIL_COLOR[this.state.currentCourseColorIndex],
                  display: "inline-block",
                  height: "12px",
                  width: "12px",
                  marginRight: "10px",
                  marginTop: "6px"
                }}
              ></span>
              <h3>{this.state.selected_courses_aggregate[addedCourseIndex].course_id}</h3>
              <button
                course-id-ref={this.state.selected_courses_aggregate[addedCourseIndex].course_id}
                quarter-ref={this.state.selected_courses_aggregate[addedCourseIndex].quarter}
                type="button"
                className="btn float-right"
                style={{
                  borderStyle: "none",
                  marginLeft: "auto",
                  color: "red",
                  fontSize: "20px",
                  marginTop: "-10px"
                }}
                onClick={this.handleCourseDeleteRef}
              >
                x
              </button>
            </div>
            <h5 style={{ color: "#BEBEBE" }}>
              {this.state.selected_courses_aggregate[addedCourseIndex].name}
            </h5>
            <h5 style={{ color: "#BEBEBE" }}>
              {" "}
              {changeQuarterName(
                this.state.selected_courses_aggregate[addedCourseIndex].quarter
              )} - {this.state.selected_courses_aggregate[addedCourseIndex].instructor}{" "}
            </h5>
            <h4 style={{ fontWeight: "bold" }}>Open Seats</h4>
            <h4 style={{ marginLeft: "10px" }}>
              {
                this.state.selected_courses_aggregate[addedCourseIndex].seats[
                  this.state.selected_courses_aggregate[addedCourseIndex].seats.length - 1
                ]
              }
            </h4>
            <h4></h4>
          </div>
        ]
      },
      () => {
        if (this.state.currentCourseColorIndex === this.COURSE_DETAIL_COLOR.length - 1) {
          this.setState({
            currentCourseColorIndex: 0
          });
        } else {
          this.setState((prevState) => ({
            currentCourseColorIndex: prevState.currentCourseColorIndex + 1
          }));
        }
      }
    );
  }

  addCourse(event) {
    if (this.state.selected_courses_aggregate.length === 4) {
      alert("Max number of courses reached. Please remove a course before adding another one");
      this.setState({
        enableAddCourse: false,
        quarter_drop_down_menu_options: [],
        instructor_drop_down_menu_options: [],
        selected_course_id: null,
        selected_course_id_quarter: null,
        selected_course_id_quarter_instructor: null
      });
      this.courseListRef.current.setState({ value: null });
      this.quarterListRef.current.setState({ value: null });
      this.instructorListRef.current.setState({ value: null });
      return;
    }

    var temp = [];
    temp.push(
      this.state.courses.filter(
        (each_course) =>
          each_course.course_id == this.state.selected_course_id &&
          each_course.instructor == this.state.selected_course_id_quarter_instructor &&
          each_course.quarter == this.state.selected_course_id_quarter
      )
    );
    console.log("after filtering in addcousrse");
    console.log(temp);
    console.log("hit in temp", temp);

    temp[0].forEach(element => this.getCourseSeats(element['_id'], temp[0].length));
  }

  addCourseComplete() {
    var temp = this.single_course_aggregates;

    var single_course = {
      graph_id: null,
      course_id: null,
      instructor: null,
      max_seats: null,
      name: null,
      quarter: null,
      seats: [],
      _id: null
    };

    if (temp.length > 0) {
      single_course.graph_id =
        temp[0].name + " (" + temp[0].quarter + ", " + temp[0].instructor + ")";
      single_course.name = temp[0].name;
      single_course.instructor = temp[0].instructor;
      single_course.max_seats = temp[0].max_seats;
      single_course.quarter = temp[0].quarter;
      single_course.course_id = temp[0].course_id;
      console.log("hit single course", single_course);
      for (var i = 0; i < temp[0].seats.length; i++) {
        var sum = 0;
        for (var j = 0; j < temp.length; j++) {
          let stuff = Object.values(temp[j].seats[i]);
          sum += stuff[0];
        }
        single_course.seats.push(sum);
      }
    }

    this.setState(
      {
        selected_courses_aggregate: [...this.state.selected_courses_aggregate, single_course],
        enableAddCourse: false,
        quarter_drop_down_menu_options: [],
        instructor_drop_down_menu_options: [],
        selected_course_id: null,
        selected_course_id_quarter: null,
        selected_course_id_quarter_instructor: null
      },
      () => {
        console.log('Course added to "this.state.selected_courses_aggregate"');
        this.courseListRef.current.setState({ value: null });
        this.quarterListRef.current.setState({ value: null });
        this.instructorListRef.current.setState({ value: null });
        this.single_course_aggregates = [];
        this.formatData();
      }
    );
  }

  handleInstructureChange(event) {
    // set the selected instructor
    this.state.selected_course_id_quarter_instructor = event.value;

    // course is not eligible to be added
    this.setState({ enableAddCourse: true });
  }

  handleQuarterChange(event) {
    // set the selected quarter
    console.log("before");
    console.log(event);
    this.state.selected_course_id_quarter = event.value;

    // clear the selected instructor
    this.state.selectedInstructor = null;

    // filter our course info to get only the courses that match our selected course
    var filteredIDInfo = this.state.courses.filter(
      (course) => course.course_id == this.state.selected_course_id
    );
    console.log("here in the filteredIDInfo");
    console.log(filteredIDInfo);
    // filter further to get only the courses that match our selected quarter
    var filteredQuarterInfo = filteredIDInfo.filter(
      (course) => course.quarter == this.state.selected_course_id_quarter
    );

    // create a new set of instructors
    // we have a set so that we only display unique instructors, not repeated
    console.log("after filttering");
    console.log(filteredQuarterInfo);
    var instructorSet = new Set();
    filteredQuarterInfo.forEach((quarter) => {
      instructorSet.add(quarter.instructor);
    });
    console.log("here");
    console.log(instructorSet);
    // create instructor list
    var instructorList = [];
    instructorSet.forEach((instructor) => {
      instructorList.push({
        value: instructor,

        // as displayed on the dropdown menu
        label: <h5 style={{ marginTop: "5px" }}>{instructor}</h5>
      });
    });
    /*
        // if we have more than 1 instructor, then give the option for All instructors
        if (instructorList.length > 1) {
          instructorList.push({
            value: "All Instructors",
    
            // as displayed on the dropdown menu
            label: <h5 style={{marginTop: "5px"}}>All Instructors</h5>
          });
        }
    */
    // reset the previous selected instructor
    this.instructorListRef.current.setState({ value: null });

    // rerender the list of instructors
    this.setState({
      instructor_drop_down_menu_options: instructorList,
      enableAddCourse: false
    });
  }

  handleCourseChange(event) {
    console.log(event);

    // get the selected course and filter course detail info for desired course ID
    this.state.selected_course_id = event.value.split(" ")[0];
    // if new course is selected, reset the previous selected quarter and instructor
    this.state.selected_course_id_quarter = null;
    this.state.selected_course_id_quarter_instructor = null;

    // clear the instructor options
    this.state.instructor_drop_down_menu_options = [];

    // filter the courses to match our course id
    var filteredInfo = this.state.courses.filter(
      (course) => course.course_id == this.state.selected_course_id
    );
    console.log(filteredInfo);
    // create a set of quarters so that we don't display any repeated quarters
    var quarterSet = new Set();
    filteredInfo.forEach((course) => {
      quarterSet.add(course.quarter);
    });

    // create our quarter list to display
    var quarterList = [];
    quarterSet.forEach((quarter) => {
      // Change abbreviated quarter names into full names to display
      const abbreviation = quarter;
      let fullName = "";
      if (abbreviation.substring(0, 2) !== "SS") {
        switch (abbreviation.substring(0, 2)) {
          case "FQ":
            fullName = "Fall Quarter " + abbreviation.substring(2);
            break;
          case "WQ":
            fullName = "Winter Quarter " + abbreviation.substring(2);
            break;
          case "SQ":
            fullName = "Spring Quarter " + abbreviation.substring(2);
            break;
        }
      } else {
        switch (abbreviation.substring(2, 3)) {
          case "1":
            fullName = "Summer Session 1 " + abbreviation.substring(3);
            break;
          case "2":
            fullName = "Summer Session 2 " + abbreviation.substring(3);
            break;
        }
      }
      quarterList.push({
        value: abbreviation,
        // as seen on the display
        label: <h5 style={{ marginTop: "5px" }}>{fullName}</h5>
      });
    });

    // set the state to rerender
    this.setState({
      quarter_drop_down_menu_options: quarterList,
      enableAddCourse: false
    });
    this.quarterListRef.current.setState({ value: null });
    this.instructorListRef.current.setState({ value: null });
  }

  render(props) {
    var borderStyle = "none";

    var classPlaceholder = (
      <h5 style={{ marginTop: "5px", color: "#BEBEBE" }}> Search for a class </h5>
    );

    var quarterPlaceholder = <h5 style={{ marginTop: "5px", color: "#BEBEBE" }}> Quarter </h5>;

    var instructorPlaceholder = (
      <h5 style={{ marginTop: "5px", color: "#BEBEBE" }}> Instructor </h5>
    );

    return (
      <>
        <img
          src={bg}
          style={{ width: "100%", height: "70px", objectFit: "cover", objectPosition: "0px 0px" }}
        />
        <div
          style={{
            overflowX: "hidden",
            top: "70px",
            height: "calc(100vh - 70px)",
            width: "auto",
            backgroundColor: "white"
          }}
        >
          <div
            className="row"
            style={{
              border: borderStyle,
              marginTop: "3rem",
              marginBottom: "3rem"
            }}
          >
            <div className="col-1" style={{ border: borderStyle }} />
            <div className="col-3" style={{ border: borderStyle }}>
              <WindowedSelect
                ref={this.courseListRef}
                onChange={this.handleCourseChangeRef}
                options={this.state.course_drop_down_menu_options}
                filterOption={({ value }, input) => {
                  const subj = value.substring(0, 3),
                    course = value.substring(3);
                  if (input.length <= 3) {
                    return subj.includes(input.toUpperCase());
                  } else {
                    return (
                      subj == input.substring(0, 3).toUpperCase() &&
                      course.includes(input.substring(3))
                    );
                  }
                }}
                placeholder={classPlaceholder}
              />
            </div>
            <div className="col-3" style={{ border: borderStyle }}>
              <WindowedSelect
                ref={this.quarterListRef}
                placeholder={quarterPlaceholder}
                isDisabled={this.state.quarter_drop_down_menu_options.length == 0}
                options={this.state.quarter_drop_down_menu_options}
                onChange={this.handleQuarterChangeRef}
              />
            </div>
            <div className="col-3" style={{ border: borderStyle }}>
              <WindowedSelect
                ref={this.instructorListRef}
                placeholder={instructorPlaceholder}
                isDisabled={this.state.instructor_drop_down_menu_options.length == 0}
                options={this.state.instructor_drop_down_menu_options}
                onChange={this.handleInstructureChangeRef}
              />
            </div>
            <div className="col-1" style={{ border: borderStyle }}>
              <button
                type="button"
                className="btn btn-primary"
                disabled={!this.state.enableAddCourse}
                onClick={this.addCourseRef}
                style={{
                  fontSize: "15px",
                  borderRadius: "5px",
                  width: "125px",
                  height: "40px",
                  backgroundColor: "#162857"
                }}
              >
                Add Class
              </button>
            </div>
            <div className="col-1" style={{ border: borderStyle }} />
          </div>

          {/* graph and legend row*/}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              border: borderStyle,
              margin: "2% 4% 0 4%",
              width: "auto",
              height: "70vh",
              alignItems: "stretch",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                flexGrow: 1,
                flexShrink: 1,
                width: "65%",
                border: borderStyle
              }}
            >
              <ResponsiveContainer>
                <LineChart
                  width="99.8%"
                  height="99.8%"
                  data={this.state.formatted_data_for_graph}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: "15px" }} tickLine={false} />
                  <YAxis tick={{ fontSize: "15px" }} unit="%" />
                  <Tooltip />
                  <Legend content={<div></div>} />
                  {this.state.chartLegend}
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div
              style={{
                display: "flex",
                width: "35%",
                height: "auto",
                border: borderStyle,
                alignSelf: "center",
                justifyContent: "center"
              }}
            >
              <div
                className="table table-borderless"
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "10px",
                  width: "auto"
                }}
              >
                <tbody>
                  <tr>
                    <td>{this.state.courseCards[0]}</td>
                    <td>{this.state.courseCards[1]}</td>
                  </tr>
                  <tr>
                    <td>{this.state.courseCards[2]}</td>
                    <td>{this.state.courseCards[3]}</td>
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
