import React, { useState } from "react";
import BtnGrid from "./BtnGrid";
import gradeimage from "../images/grade.png";
import enrollmentimage from "../images/enrollment.png";
import classesimage from "../images/coming-soon.png";
import plannerimage from "../images/coming-soon.png";
import "../styles/feature-section.css";

export default function Features({ id }) {
  const [figureID, setFigureID] = useState(0);
  // format feature button info for BtnGrid component
  const featureButtons = [
    {
      name: "Grade Distribution",
      desc: "We provide grade distributions for most UC Davis courses, along with the terms and professors that teach those courses."
    },
    {
      name: "Enrollment Probability",
      desc: "We provide enrollment probability for most UC Davis courses to help you gauge your class admissions"
    },
    {
      name: "Save Classes",
      desc: "Save past class statistics for fast informative quantitative data that may inform your decisions to take a class or not."
    },
    {
      name: "Your Planner",
      desc: "Build your class schedule for the quarter by saving classes and customizing your planner."
    }
  ];
  // feature figures, where ID are the indices 0..3
  const figures = [gradeimage, enrollmentimage, classesimage, plannerimage];

  return (
    <div id="featuresContainer">
      <h1 id="featurestitle">Features</h1>
      <div id="card">
        <img id="cardImage" src={figures[figureID]} alt="FIGURE" />
      </div>
      <BtnGrid buttons={featureButtons} setFunction={setFigureID}></BtnGrid>
    </div>
  );
}
