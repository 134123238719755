import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../styles/about-page.css";
import andrewimage from "../images/team-headshots/Andrew.png";
import derekimage from "../images/team-headshots/Derek.jpeg";
import giovannaimage from "../images/team-headshots/Giovanna.jpeg";
import minhtuimage from "../images/team-headshots/minhtu.jpg";
import poojaimage from "../images/team-headshots/Pooja.png";
import rizkimage from "../images/team-headshots/Rizk.png";
import robertimage from "../images/team-headshots/Robert.png";
import elizabethimage from "../images/team-headshots/elizabeth.jpeg";
import prabhdeepimage from "../images/team-headshots/prabhdeep.jpg";
import sreeramimage from "../images/team-headshots/Sreeram.png";
import Footer from "../components/Footer.jsx";
import bg from "../images/home-bg.png";
import gi from "../images/team-headshots/teamphoto.jpg"


export default class AboutPage extends Component {
  render() {
    return (
      <div>
         <img
          src={bg}
          style = {{width: "100%", height: "70px", objectFit: "cover", objectPosition: "0px 0px"}}
          />
        <div class="About">
          <div class="row" id="AboutOurTeam">
            <div id="aboutimage" class="col-md-7">
              <img src={gi} id="group" />
            </div>
            <div id="aboutdescription" class="col-md-5">
              <div id="aboutinfo">
                <h1 id="abouttitle">
                  <b>About Our Team</b>
                </h1>
                <div id="aboutdescrip">
                  AggieExplorer was built by a small team of developers and designers during the
                  Winter/Spring 2021 cohort of CodeLab! Our goal was to create a web platform to
                  help students at UC Davis make better decisions on their course choices by
                  providing extra information. Visit our CodeLab site{" "}
                  <a href="https://codelabdavis.com/" target="_blank">
                    <b>here</b>
                  </a>{" "}
                  to learn more about our organization and the different projects we do!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="About2">
          <div class="row" id="AboutOurTeam2">
            <div id="aboutimage2">
              <img src={gi} id="group2" />
            </div>
            <div id="aboutdescription2">
              <div id="aboutinfo2">
                <h1 id="abouttitle2">
                  <b>About Our Team</b>
                </h1>
                <div id="aboutdescrip2">
                  AggieExplorer was built by a small team of developers and designers during the
                  Winter/Spring 2021 cohort of CodeLab! Our goal was to create a web platform to
                  help students at UC Davis make better decisions on their course choices by
                  providing extra information. Visit our CodeLab site{" "}
                  <a href="https://codelabdavis.com/" target="_blank">
                    <b>here</b>
                  </a>{" "}
                  to learn more about our organization and the different projects we do!
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="members">
          <div class="memberstitle">
            <b>Current Members</b>
          </div>
          <div class="container px-5 py-5">
            <div class="row">
              <div class="col pr-3 pl-3">
                <img
                  src={minhtuimage}
                  id="minhtu"
                  class="imageClick"
                  onClick={() => window.open("https://www.linkedin.com/in/minhtumnguyen/")}
                />
                <div class="name">
                  <b>Minh-Tu Nguyen</b>
                </div>
                <div class="position">Project Manager</div>
              </div>

              <div class="col pr-3 pl-3">
                <img
                  src={prabhdeepimage}
                  id="prabhdeep"
                  class="imageClick"
                  onClick={() => window.open("https://www.linkedin.com/in/prabhdeep-kainth/")}
                />
                <div class="name">
                  <b>Prabhdeep Kainth</b>
                </div>
                <div class="position">Project Manager</div>
              </div>
              <div class="col pr-3 pl-3">
                <img
                  src={derekimage}
                  id="derek"
                  class="imageClick"
                  // onClick={() => window.open("https://www.linkedin.com/in/omidmogasemi/")}
                />
                <div class="name">
                  <b>Derek Ma</b>
                </div>
                <div class="position">Software Engineer</div>
              </div>
              <div class="col pr-3 pl-3">
                <img
                  src={sreeramimage}
                  id="sreeram"
                  class="imageClick"
                  onClick={() => window.open("https://www.linkedin.com/in/ssandrapati477/")}
                />
                <div class="name">
                  <b>Sreeram Sandrapati</b>
                </div>
                <div class="position">Software Engineer</div>
              </div>
            </div>
          </div>
          <div class="container px-5 py-5">
            <div class="row">
              {/* <div class="col pr-3 pl-3">
                            <img src={elizabethimage} id="elizabeth"/>
                            <div class="name"><b>Elizabeth Hopkins</b></div>
                            <div class="position">Software Engineer</div>
                        </div> */}

              <div class="col pr-3 pl-3">
                <img
                  src={robertimage}
                  id="robert"
                  class="imageClick"
                  onClick={() => window.open("https://www.linkedin.com/in/robert-au-975272198")}
                />
                <div class="name">
                  <b>Robert Au</b>
                </div>
                <div class="position">Software Engineer</div>
              </div>

              {/* <div class="col pr-3 pl-3">
                            <img src={andrewimage} id="andrew" class="imageClick" onClick={() => window.open("https://www.linkedin.com/in/jamesjunaidi/")}/>
                            <div class="name"><b>Andrew Song</b></div>
                            <div class="position">Software Engineer</div>
                        </div> */}
              <div class="col pr-3 pl-3">
                <div class="name">
                  <img
                    src={rizkimage}
                    id="rizk"
                    class="imageClick"
                    onClick={() =>
                      window.open("https://www.linkedin.com/in/ibrahim-rizk-980289133/")
                    }
                  />
                </div>
                <div class="name">
                  <b>Ibrahim Rizk</b>
                </div>
                <div class="position">Software Engineer</div>
              </div>
              <div class="col pr-3 pl-3">
                <img
                  src={poojaimage}
                  id="pooja"
                  class="imageClick"
                  onClick={() => window.open("http://www.linkedin.com/in/ppdesh")}
                />
                <div class="name">
                  <b>Pooja Deshmukh</b>
                </div>
                <div class="position">Designer</div>
              </div>
              <div class="col pr-3 pl-3">
                <img
                  src={giovannaimage}
                  id="giovanna"
                  class="imageClick"
                  // onClick={() => window.open("https://www.linkedin.com/in/gennifer-hom/")}
                />
                <div class="name">
                  <b>Giovanna Briano</b>
                </div>
                <div class="position">Designer</div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="members2">
                        <div class="memberstitle2"><b>Current Members</b></div>  
                        <div class="container">                    
                            <div class="row">
                                <div class="col-6">
                                    <img src={minhtuimage} id="image2"/>
                                    <div class="name2"><b>Minh-Tu Nguyen</b></div>
                                    <div class="position2">Project Manager</div>
                                </div>
                                <div class="col-6">
                                    <img src={poojaimage} id="image2r"/>
                                    <div class="name2r"><b>Pooja Deshmukh</b></div>
                                    <div class="position2r">Designer</div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <div class="name2"><img src={rizkimage} id="image2"/></div>
                                <div class="name2"><b>Ibrahim Riz</b></div>
                                <div class="position2">Software Engineer</div>
                            </div>
                            <div class="col-6">
                                <img src={sreeramimage} id="image2r"/>
                                <div class="name2r"><b>Sreeram Sandrapati</b></div>
                                <div class="position2r">Software Engineer</div>
                            </div>
                        </div>
                        </div>
                        <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <img src={robertimage} id="image2"/>
                                <div class="name2"><b>Robert Au</b></div>
                                <div class="position2">Software Engineer</div>
                            </div>
                            <div class="col-6">
                                <img src={elizabethimage} id="image2r"/>
                                <div class="name2r"><b>Elizabeth Hopkins</b></div>
                                <div class="position2r">Software Engineer</div>
                            </div>
                        </div>
                        </div>
                        <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <img src={prabhdeepimage} id="image2"/>
                                <div class="name2"><b>Prabhdeep Kainth</b></div>
                                <div class="position2">Project Manager</div>
                            </div>
                            <div class="col-6">
                                <img src={andrewimage} id="image2r"/>
                                <div class="name2r"><b>Andrew Song</b></div>
                                <div class="position2r">Software Engineer</div>
                            </div>
                        </div>
                        </div>
                        <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <img src={derekimage} id="image2"/>
                                <div class="name2"><b>Derek Ma</b></div>
                                <div class="position2">Software Engineer</div>
                            </div>
                            <div class="col-6">
                                <img src={giovannaimage} id="image2r"/>
                                <div class="name2r"><b>Giovanna Briano</b></div>
                                <div class="position2r">Designer</div>
                            </div>
                        </div>  
                        </div>
                    </div> */}
        <Footer />
      </div>
    );
  }
}