import React, { useState } from "react";
// import axios from "axios";
import "../styles/dashboard.css";
import UserProfile from "../components/UserProfile";
import UserPlanner from "../components/UserPlanner";
import UserStats from "../components/UserStats";
import bg from "../images/home-bg.png";
const RightContents = {
  profile: UserProfile,
  planner: UserPlanner,
  stats: UserStats
};

const Dashboard = () => {
  // Check if user is logged in
  const clientID = sessionStorage.getItem("userToken");
  if(!clientID) { window.location = "/" }

  const [currentTab, setcurrentTab] = useState("profile");

  const handleClick = (id) => {
    setcurrentTab(id);
  };

  const CurrentRightContent = RightContents[currentTab];

  return (
    <>
      <img
        src={bg}
        style={{ width: "100%", height: "70px", objectFit: "cover", objectPosition: "0px 0px" }}
        alt=""
      />
      <div id="dashContent">
        <div id="leftNav">
          <div id="topAlignNav">
            <div
              id="profile"
              onClick={() => handleClick("profile")}
              className={"profile" === currentTab ? "navButton is-active" : "navButton"}
            >
              Profile
            </div>
            <div
              id="planner"
              onClick={() => handleClick("planner")}
              className={"planner" === currentTab ? "navButton is-active" : "navButton"}
            >
              Your Planner
            </div>
            <div
              id="stats"
              onClick={() => handleClick("stats")}
              className={"stats" === currentTab ? "navButton is-active" : "navButton"}
            >
              Saved Statistics
            </div>
          </div>
          <div id="botAlignNav">
            <div className="navButton">Help</div>
            <div className="navButton">Sign Out</div>
          </div>
        </div>
        <div id="rightContent">
          <CurrentRightContent />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
