import React from "react";

export default function StatsCard({ course, active, setActive }) {
  const gradeLegend = {
    aplus: ["A+", 4.0],
    a: ["A", 4.0],
    aminus: ["A-", 3.7],
    bplus: ["B+", 3.3],
    b: ["B", 3.0],
    bminus: ["B-", 2.7],
    cplus: ["C+", 2.3],
    c: ["C", 2.0],
    cminus: ["C-", 1.7],
    dplus: ["D+", 1.3],
    d: ["D", 1.0],
    dminus: ["D-", 0.7],
    f: ["F", 0.0]
  };

  const findCourseAvg = (course) => {
    let total = 0;
    Object.keys(gradeLegend).forEach((grade) => (total += course[grade]));
    const totalGrades = total;

    let pts = 0;
    Object.entries(gradeLegend).forEach((entry) => {
      pts += course[entry[0]] * entry[1][1];
    });
    const totalGradePts = pts;

    const avgGradePts = totalGradePts / totalGrades;
    const gradeArray = Object.entries(gradeLegend);
    for (let i = 0; i < gradeArray.length - 1; i++) {
      if (avgGradePts <= gradeArray[i][1][1] && avgGradePts >= gradeArray[i + 1][1][1]) {
        if (
          Math.abs(avgGradePts - gradeArray[i][1][1]) >=
          Math.abs(avgGradePts - gradeArray[i + 1][1][1])
        )
          return gradeArray[i + 1][1][0];
        else {
          return gradeArray[i][1][0];
        }
      }
    }
  };

  const findPercentile = (course) => {
    let totalGrades = 0;
    Object.keys(gradeLegend).forEach((grade) => (totalGrades += course[grade]));
    const index = Math.round(0.9 * totalGrades);

    let gradeList = [];
    Object.entries(gradeLegend).forEach((entry) => {
      const numGrades = course[entry[0]];
      for (let i = 0; i <= numGrades; i++) {
        gradeList.unshift(entry[1][0]);
      }
    });

    return gradeList[index - 1];
  };

  const changeQuarterName = (quarter) => {
    const abbreviation = quarter;
    let fullName = "";
    if (abbreviation.substring(0, 2) !== "SS") {
      switch (abbreviation.substring(0, 2)) {
        case "FQ":
          fullName = "Fall Quarter " + abbreviation.substring(2);
          break;
        case "WQ":
          fullName = "Winter Quarter " + abbreviation.substring(2);
          break;
        case "SQ":
          fullName = "Spring Quarter " + abbreviation.substring(2);
          break;
      }
    } else {
      switch (abbreviation.substring(2, 3)) {
        case "1":
          fullName = "Summer Session 1 " + abbreviation.substring(3);
          break;
        case "2":
          fullName = "Summer Session 2 " + abbreviation.substring(3);
          break;
      }
    }
    return fullName;
  };

  return active[0] ? (
    <div
      className="w-full p-5 bg-[#ECF4F4] rounded-[2rem] flex flex-col gap-8 cursor-pointer"
      onClick={() => {
        setActive((prevState) => ({
          ...prevState,
          [course._id]: [false, prevState[course._id][1]]
        }));
      }}
    >
      <div>
        <div className="flex items-center gap-4">
          <div style={{ backgroundColor: active[1] }} className="w-6 h-6 mb-2 rounded-full" />
          <h1 className="font-proxima-bold text-aggie-blue text-[22px]">{`${course.subj} ${course.code}`}</h1>
        </div>
        <h2 className="font-proxima text-aggie-blue text-[18px]">{course.name}</h2>
        <h2 className="font-proxima text-aggie-blue text-[18px]">
          {`${changeQuarterName(course.quarter)} - ${course.instructor}`}
        </h2>
      </div>
      <div className="h-44 flex flex-col justify-between ">
        <div>
          <h3 className="font-proxima-bold text-aggie-blue text-[16px]">Course Average</h3>
          <h4 className="font-proxima text-aggie-blue text-[16px]">{findCourseAvg(course)}</h4>
        </div>
        <div>
          <h3 className="font-proxima-bold text-aggie-blue text-[16px]">
            79th to 100th Percentile
          </h3>
          <h4 className="font-proxima text-aggie-blue text-[16px]">{findPercentile(course)}</h4>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="w-full p-5 bg-[#F1F1F1] rounded-[2rem] flex flex-col cursor-pointer"
      onClick={() => {
        setActive((prevState) => ({
          ...prevState,
          [course._id]: [true, prevState[course._id][1]]
        }));
      }}
    >
      <div className="flex items-center gap-4">
        <div style={{ backgroundColor: active[1] }} className="w-6 h-6 mb-2 rounded-full" />
        <h1 className="font-proxima-bold text-aggie-blue text-[22px]">{`${course.subj} ${course.code}`}</h1>
      </div>
      <h2 className="font-proxima text-aggie-blue text-[18px]">{course.name}</h2>
      <h2 className="font-proxima text-aggie-blue text-[18px]">
        {`${changeQuarterName(course.quarter)} - ${course.instructor}`}
      </h2>
    </div>
  );
}
