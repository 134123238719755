
export default function SwitchButton({option1, option2, chosen}) {

    return (
        <div className="rounded-full mt-5 border border-gray-800" style={{ backgroundColor: "#F1F1F1"}}>
            <div className="flex justify-between" style={{ width: "28rem"}}>
                <span className="text-center p-3 w-56 rounded-full text-2xl font-proxima font-bold text-aggie-blue" style={chosen === 0 ? {backgroundColor:"#162857", color:"white"} : {}}>{option1}</span>
                <span className="text-center p-3 w-56 rounded-full text-2xl font-proxima font-bold text-aggie-blue" style={chosen === 1 ? {backgroundColor:"#162857", color:"white"} : {}}>{option2}</span>
            </div>
        </div>
    )
};