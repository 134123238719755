import React, { Component } from "react";
import "../styles/home-page.css";
import gradeimage from "../images/grade.png";
import enrollmentimage from "../images/enrollment.png";
import Features from "../components/FeatureSection";
import Footer from "../components/Footer.jsx";

export default class HomePage extends Component {
  render() {
    return (
      // background-image: url("localhost:3000(/img/Home%20Illustration.png");
      <div style={{ overflowX: "hidden" }}>
        {/* // <div styles={{ backgroundImage:`url(${background})`}} > */}
        <div id="intro" className="introspace">
          <div className="intro">
            {/* <img src={background} alt="img1"/> */}
            <div className="introtext">
              <h1 className="title">
                <span className="Aggie">
                  <b>Aggie</b>
                </span>
                <span className="Explorer">
                  <b>Explorer</b>
                </span>
              </h1>
              <h6 className="Description">Grade distribution and enrollment probability tool</h6>
              <p className="button">
                <a id="homebutton" className="btn btn-large btn-info" href="catalog">
                  Browse Courses
                </a>
              </p>
            </div>
            <div className="introarrow">
              <div className="whatwedo">
                <b>What we do</b>
              </div>
              <a href="#mission">
                <div className="arrow"></div>
              </a>
            </div>
          </div>
        </div>
        <div id="mission" className="mission">
          <h1 id="missionleft" className="col-md-5">
            <h1 style={{ fontSize: "40px" }}>Our Mission</h1>
          </h1>
          <div id="missionright" className="col-md-7">
            To help provide transparency and additional information about UC Davis courses in order
            to help students make better decisions during course registration.
          </div>
        </div>
        <div id="mission2" className="mission" media="screen and (max-device-width: 375px)">
          <h1 id="missionleft2">
            <b>Our Mission</b>
          </h1>
          <div id="missionright2">
            To help provide transparency and additional information about UC Davis courses in order
            to help students make better decisions during course registration.
          </div>
        </div>
        <div className="features">
          <div id="featuresSection" className="container">
            {/*
            <div id="gradecard" className="cards">
              <div className="card-body">
                <div id="gradecardimage">
                  <img src={gradeimage} id="gradeimage" />
                </div>
                <div id="gradecardtitle">
                    <b>Grade Distribution</b>
                  </div>
                  <div id="gradecarddescription">
                    We provide grade distributions for most UC Davis courses, along with the
                    terms and professors that teach those courses.
                  </div>
              </div>
            </div> 
            */}
            <Features></Features>
            {/* <div className="col-md-1 justify-content-center"></div> */}
            {/* <div id="featuresright" className="col-md-5 justify-content-center">
                <a href="/enrollment">
                  <div id="enrollmentcard" className="cards">
                    <div id="enrollmentcardimage">
                      <img src={enrollmentimage} id="enrollmentimage" />
                    </div>
                    <div id="enrollmentcardtitle">
                      <b>Enrollment Probability</b>
                    </div>
                    <div id="enrollmentcarddescription">
                      We provide information on how many students are enrolling for courses within
                      each day of the scheduling period.
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-1 justify-content-center"></div> */}
          </div>
        </div>

        <div className="features2" media="screen and (max-device-width: 375px)">
          <div className="featurestitle2">
            <b>Features</b>
          </div>
          <div className="container2">
            <div id="featuresleft2">
              <a href="grades">
                <div id="gradecard2" className="cards2">
                  <div className="card-body2">
                    <div id="gradecardimage2">
                      <img src={gradeimage} id="gradeimage2" />
                    </div>
                    <div id="gradecardtitle2">
                      <b>Grade Distribution</b>
                    </div>
                    <div id="gradecarddescription2">
                      We provide grade distributions for most UC Davis courses, along with the terms
                      and professors that teach those courses.
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div id="featuresright2">
              <a href="search">
                <div id="enrollmentcard2" className="cards2">
                  <div id="enrollmentcardimage2">
                    <img src={enrollmentimage} id="enrollmentimage2" />
                  </div>
                  <div id="enrollmentcardtitle2">
                    <b>Enrollment Probability</b>
                  </div>
                  <div id="enrollmentcarddescription2">
                    We provide information on how many students are enrolling for courses within
                    each day of the scheduling period.
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
