import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

export default function StatsGraph({ courses, active }) {
  const [data, setData] = useState([]);
  const [bars, setBars] = useState([]);
  const gradeLegend = {
    "A+": "aplus",
    "A": "a",
    "A-": "aminus",
    "B+": "bplus",
    "B": "b",
    "B-": "bminus",
    "C+": "cplus",
    "C": "c",
    "C-": "cminus",
    "D+": "dplus",
    "D": "d",
    "D-": "dminus",
    "F": "f"
  };

  const findGradeCount = (course) => {
    let count = 0;
    Object.values(gradeLegend).forEach((value) => {
      count += course[value];
    });
    return count;
  };

  useEffect(() => {
    if (!_.isEmpty(active)) {
      let graphData = [];
      Object.entries(gradeLegend).forEach((entry, index) => {
        graphData.push({
          name: entry[0]
        });
        courses.forEach((course) => {
          const totalGradeCount = findGradeCount(course);
          graphData[index][course.course_id] = ((course[entry[1]] / totalGradeCount) * 100).toFixed(
            2
          );
        });
      });
      setData(graphData);
    }
  }, [active]);

  useEffect(() => {
    let barContainer = [];
    courses.forEach((course) => {
      if (active[course._id][0]) {
        barContainer.push(
          <Bar
            key={`${course._id}-bar`}
            dataKey={course["course_id"]}
            fill={active[course._id][1]}
          />
        );
      }
    });
    setBars(barContainer);
  }, [data]);

  return (
    <ResponsiveContainer width="90%" height="90%">
      <BarChart layout="vertical" data={data}>
        <CartesianGrid stroke="#ECECEC" />
        <XAxis
          type="number"
          tick={{ fontFamily: "Proxima Nova", fontSize: "14px" }}
          unit="%"
          tickLine={false}
          domain={[0, 100]}
          orientation="top"
        />
        <YAxis
          dataKey="name"
          type="category"
          tick={{ fontFamily: "Proxima Nova", fontSize: "14px" }}
          tickLine={false}
        />
        <Tooltip
          formatter={(value) => value + "%"}
          labelStyle={{ fontFamily: "Proxima Nova Bold", fontSize: "12px" }}
          itemStyle={{ fontFamily: "Proxima Nova", fontSize: "12px" }}
          contentStyle={{ borderRadius: "10px" }}
        />
        <Legend
          iconType="circle"
          wrapperStyle={{ position: "relative", fontFamily: "Proxima Nova" }}
        />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}
