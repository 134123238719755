import React from "react";
import { GoogleOAuthProvider, GoogleLogin, googleLogout } from "@react-oauth/google";
import * as ReactBootstrap from "react-bootstrap";
import "../styles/navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

// Styles
const googleBtn = {
  display: "inline-flex",
  height: "40px",
  width: "100px",
  border: "1px solid #dadce0",
  color: "#3c4043",
  backgroundColor: "#fff"
};
const profileBtn = {
  backgroundColor: "#fff",
  border: "1px solid #dadce0",
  color: "#3c4043",
  height: "40px",
  width: "80px",
  marginRight: "20px"
};
// we wrap NavBar in withRouter(), this adds history to our props
// -- history is NOT a property that is passed in from App
function NavBar({ currentID }) {
  const handleLogin = (googleData) => {
    const params = googleData.credential;
    const url = "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + params
    fetch(url)
      .then(response => response.json())
      .then(res => {
        const usrToken = res.email;
        console.log(usrToken);
        sessionStorage.setItem("userToken", usrToken);
        console.log(sessionStorage.getItem("userToken"))
      })
      .then(() => {
        setTimeout(()=>{window.location = "/dashboard";}, 500)
      });
  };

  const handleFailure = (res) => {
    console.log("Error:", res);
  };

  const handleLogout = () => {
    googleLogout();
    console.log("Sucessful logout");
    console.log(sessionStorage.getItem("userToken"))
    sessionStorage.removeItem("userToken");
    window.location = "/";
    //setAppLoggedIn(false);
  };

  const redirectToDashboard = () => {
    window.location = "/dashboard";
  };

  //const inDashboard = (window.location.pathname == "/dashboard");
  //console.log("Am i in dashboard:", inDashboard);

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <ReactBootstrap.Navbar collapseOnSelect expand="md" id="navbar">
        <ReactBootstrap.Navbar.Brand href="/" id="navbartitle">
          Aggie Explorer
        </ReactBootstrap.Navbar.Brand>
        <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
          <ReactBootstrap.Nav.Link href="/catalog" className="navlinksspace">
            Catalog
          </ReactBootstrap.Nav.Link>
          <ReactBootstrap.Nav.Link href="/grades" className="navlinksspace">
            Grades
          </ReactBootstrap.Nav.Link>
          <ReactBootstrap.Nav.Link href="/enrollment" className="navlinksspace">
            Enrollment
          </ReactBootstrap.Nav.Link>
          <ReactBootstrap.Nav.Link href="/about" className="navlinksspace">
            About
          </ReactBootstrap.Nav.Link>
        </ReactBootstrap.Navbar.Collapse>
        {/* <button id="signInButton" href="/dashboard">Sign In</button> */}
        {!currentID ? (
          <GoogleLogin
            onSuccess={(credentialResponse) => handleLogin(credentialResponse)}
            onError={(err) => handleFailure(err)}
            text="signin"
            width="100px"
          />
        ) : (
          <div>
            <button
              onClick={() => redirectToDashboard()}
              className="font-proxima font-bold rounded-md items-center justify-center"
              style={profileBtn}
            >
              Profile
            </button>
            <button
              className="font-proxima font-bold rounded-md items-center justify-center"
              style={googleBtn}
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        )}
      </ReactBootstrap.Navbar>
    </GoogleOAuthProvider>
  );
}

export default NavBar;
