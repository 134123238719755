import React, { useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
import StatsCard from "./StatsCard";
import StatsTab from "./StatsTab";
import ViewAllBtn from "./ViewAllBtn";
import StatsGraph from "./StatsGraph";

export default function UserStats() {
  const [courses, setCourses] = useState([]);
  const [active, setActive] = useState({});
  const [stats, setStats] = useState({});
  const colors = [
    "#FFC519",
    "#E16E6E",
    "#95C5FB",
    "#70AD59",
    "#6DB793",
    "#B15DD8",
    "#6B69E4",
    "#E7A576"
  ];
  const clientID = sessionStorage.getItem("userToken");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client_data`, {
        params: { client_id: clientID }
      })
      .then((profile) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/detail/id`, {
            params: { ids: profile.data.data.course_object_ids }
          })
          .then((res) => {
            setCourses(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (courses.length !== 0) {
      let initialActive = {},
        currentColorIndex = 0;
      courses.forEach((course) => {
        initialActive[course._id] = [false, colors[currentColorIndex]];
        currentColorIndex === colors.length - 1 ? (currentColorIndex = 0) : currentColorIndex++;
      });
      setActive(initialActive);
    }
  }, [courses]);

  useEffect(() => {
    let statsHolder = {};
    courses.forEach((course) => {
      statsHolder[course._id] = [
        <StatsTab
          key={`${course._id}-tab`}
          course={course}
          active={active[course._id]}
          setActive={setActive}
        />,
        <StatsCard
          key={`${course._id}-card`}
          course={course}
          active={active[course._id]}
          setActive={setActive}
        />
      ];
    });
    setStats(statsHolder);
  }, [active]);

  const handleViewAll = (isActive) => {
    let activeHolder = {};
    courses.forEach((course) => {
      activeHolder[course._id] = [isActive, active[course._id][1]];
    });
    setActive(activeHolder);
  };

  return (
    <div className="h-full">
      <h1 className="font-proxima-semi-bold text-aggie-blue text-[26px] mb-8">Saved Statistics</h1>
      <div className="flex items-start justify-between">
        <div className="w-2/5 flex flex-col gap-8">
          <div className="flex w-full gap-4 flex-wrap">
            <ViewAllBtn active={active} handleViewAll={handleViewAll} />
            {Object.entries(stats).map((element) => element[1][0])}
          </div>
          <div className="flex flex-col gap-16">
            {Object.entries(stats).map((element) => element[1][1])}
          </div>
        </div>
        <div className="w-3/5 h-[650px] flex justify-center items-center">
          <StatsGraph courses={courses} active={active} />
        </div>
      </div>
    </div>
  );
}
