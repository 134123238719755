import React from "react";
import InstagramIcon from "../images/instagram-icon.svg";
import FacebookIcon from "../images/facebook-icon.svg";
import CodelabLogo from "../images/codelab-logo.svg";

export default function Footer() {
  return (
    <div className="w-full h-auto justify-between grid grid-cols-1 sm:grid-cols-3 gap-y-20 sm:gap-y-28 px-16 sm:px-24 md:px-56 py-24 items-center lg:flex lg:flex-row lg:items-start">
      <div className="sm:col-span-3 lg:w-1/3 w-full flex flex-col">
        <h1 className="font-proxima-bold text-[24px] md:text-[30px] text-aggie-blue mb-4">
          AggieExplorer
        </h1>
        <p className="font-proxima text-[14px] md:text-[18px] text-aggie-blue">
          We are a student run website whose goals is to help UC Davis students find the right
          courses for them.
        </p>
        <div className="flex mt-4 w-full items-center">
          <a href="https://www.codelabdavis.com/">
            <p className="font-proxima mb-2">Created with 💙 by</p>
            <img src={CodelabLogo} alt="Codelab Logo" className="h-6 md:h-8 mr-3" />
          </a>
          <a href="https://www.instagram.com/codelabdavis/">
            <img src={InstagramIcon} alt="Instagram Icon" className="w-8 md:w-10 mx-3" />
          </a>
          <a href="https://www.facebook.com/CodeLabDavis">
            <img src={FacebookIcon} alt="Facebook Icon" className="w-8 md:w-10 mx-3" />
          </a>
        </div>
      </div>
      <div className="w-auto flex flex-col items-center lg:items-start">
        <h1 className="font-proxima-semi-bold text-[16px] md:text-[18px] mb-5 text-aggie-blue">
          HOME
        </h1>
        <a className="font-proxima text-[16px] md:text-[18px] my-2 text-aggie-blue">Sign In</a>
        <a
          href="/About"
          className="font-proxima text-[16px] md:text-[18px] my-2 hover:underline text-aggie-blue"
        >
          About
        </a>
        <a className="font-proxima text-[16px] md:text-[18px] my-2 text-aggie-blue">
          Browse Courses
        </a>
      </div>
      <div className="w-auto flex flex-col items-center lg:items-start">
        <h1 className="font-proxima-semi-bold text-[16px] md:text-[18px] mb-5 text-aggie-blue">
          GET STARTED
        </h1>
        <a
          href="/Catalog"
          className="font-proxima text-[16px] md:text-[18px] my-2 hover:underline text-aggie-blue"
        >
          Catalog
        </a>
        <a
          href="/Grades"
          className="font-proxima text-[16px] md:text-[18px] my-2 hover:underline text-aggie-blue"
        >
          Grades
        </a>
        <a
          href="/Enrollment"
          className="font-proxima text-[16px] md:text-[18px] my-2 hover:underline text-aggie-blue"
        >
          Enrollment
        </a>
      </div>
      <div className="w-auto flex flex-col items-center lg:items-start">
        <h1 className="font-proxima-semi-bold text-[16px] md:text-[18px] mb-5 text-aggie-blue">
          MY ACCOUNT
        </h1>
        <a className="font-proxima text-[16px] md:text-[18px] my-2 text-aggie-blue">Profile</a>
        <a className="font-proxima text-[16px] md:text-[18px] my-2 text-aggie-blue">Your Planner</a>
        <a className="font-proxima text-[16px] md:text-[18px] my-2 text-aggie-blue">
          Saved Statistics
        </a>
      </div>
    </div>
  );
}
