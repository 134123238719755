import React, { Fragment, useState } from "react";

export default function ClassTabGrid({ courses, colors, sendDelete }) {
  // TODO : should we change this alert to make a pretty popup?
  const readCourseDesc = (e) => {
    alert(e)
  };
  
  const ClassCircle = ({ color }) => {
    const circle_color = {
      width: "1.25rem",
      height: "1.25rem",
      backgroundColor: color
    };
    return (
      <Fragment>
        <span className="mr-3.5 mt-2.5 rounded-full" style={circle_color}></span>
      </Fragment>
    )
  }

  return (
    <div className="grid grid-cols-2 gap-8 justify-center mt-5 mb-5">
      {courses.map((c, i) => (
        <div
          key={i}
          name={c.name}
          className={"flex flex-col px-10 py-6 rounded-3xl"}
          style={{ backgroundColor: "#F1F1F1"}}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-row justify-start">
              <ClassCircle color={colors[i]}></ClassCircle>
              <h2 id="abbName" className="font-proxima font-bold text-aggie-blue">{c.course_id}</h2>
            </div>
            <div onClick={() => sendDelete(c)} className="px-3 pt-2 bg-red-600 rounded-full">
              <div className="text-white"> X </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <p id="fullName" className="font-proxima font-medium text-2xl text-aggie-blue">{c.fullName}</p>
            <p id="units" className="font-proxima text-2xl text-aggie-blue">{c.units + " Units"}</p>
          </div>
          <p id="profAndYear" className="font-proxima text-2xl text-aggie-blue">{c.instructor + " " + c.year}</p>
          <div>
            <p className="font-proxima text-xl italic text-aggie-blue" onClick={() => readCourseDesc(c.desc)}>Read Description</p>
          </div>
        </div>
      ))}
    </div>
  );
}
